import React from 'react'
import './about.css'
import ME from '../../assests/me2.jpeg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
<section  id = 'about' >
<h2 className='aboutMe'>About Me</h2>


<div className="container about__container">
  <div className="about__me">
    <div className="about__me-image">
      <img src={ME} alt="AboutImage" />
    </div>
  </div>
  <div className="about__content">
  <div className="about__cards">
    
    <article className = "about__card">
      <FiUsers className ='about__icon' />
      <h5>Education</h5>
      <small>Computer Science MS</small>

    </article>
    <article className = "about__card">
      <VscFolderLibrary className ='about__icon' />
      <h5>Projects</h5>
      <small>10+ completed</small>

    </article>
    </div>
    <p>
    I am Jibin Biju, a Computer Science Master Student with experience from the MIT Full Stack Bootcamp. I have a passion for coding and developing, which led me to pursue a career as a full stack developer. I have a strong background in web development, software engineering, and database management, and am experienced in using a variety of programming languages, frameworks, and databases. I have the ability to quickly and accurately analyze complex problems and create innovative solutions to address them. I am also proficient in project management, with a track record of successful project completion. I am excited to use my skills and knowledge to help create innovative and successful projects.    </p>

  </div>
</div>

</section>
    )
}

export default About
import React from 'react'
import { FaFacebookF } from 'react-icons/fa'
import { FiInstagram } from 'react-icons/fi'
import { IoLogoTwitter } from 'react-icons/io'


import './footer.css'

const Footer = () => {
  return (
    <footer>
        <a href="#" className='footer__logo'>Jibin Biju</a>

        <ul className='permalinks'>
            <li><a href="#">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#experience">Experience</a></li>
            <li><a href="#portfolio">Portfolio</a></li>
            <li><a href="#contact">Contact</a></li>
            

        </ul>
        <div className="footer__socials">
            <a href="https://www.facebook.com/profile.php?id=100009055019978" target="blank"><FaFacebookF/></a>
            <a href="https://www.instagram.com/jibinvarghese04/" target="blank"><FiInstagram/></a>
            

        </div>

        <div className="footer__copyright">
        <small>&copy; Jibin Biju. All rights reserved</small>
        </div>
    </footer>
  )
}

export default Footer
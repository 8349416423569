import React from 'react'
import Header from './components/header/Header'
import Contact from './components/contact/Contact'
import Experience from './components/experience/Experience'
import About from './components/about/About'
import Nav from './components/nav/Nav'
import Portfolio from './components/portfolio/Portfolio'
import Footer from './components/footer/Footer'
import Design from './components/Design'
const app = () => {
  return (
    
    
    <div>
      <div><Design/></div>
      
       <Header/>
       <Nav/>
       <About/>
       <Experience/>
       <Portfolio/>
       <Contact/>
       <Footer/>
       
    </div>
  )
}

export default app

import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section  id = 'experience' >
    <h2 className='aboutMe'>My Experience</h2>
    

    <div className="container experience__container">
    <div className="experience__frontend">
<h3>Frontend Development</h3>
    <div className="experience__content">
      <article className="experience__details">
      <BsPatchCheckFill className="experience__details-icon"/>
      <div><h4>HTML</h4>
      </div>
      </article>
      <article className="experience__details">
      <BsPatchCheckFill className="experience__details-icon"/>
      <div><h4>CSS</h4>
      </div>
      </article>
      <article className="experience__details">
      <BsPatchCheckFill className="experience__details-icon"/>
      <div><h4>JavaScript</h4>
      </div>
      </article>
      <article className="experience__details">
      <BsPatchCheckFill className="experience__details-icon"/>
      <div><h4>React</h4>
      </div>
      </article>
    </div>
</div>
<div className="experience__backend">
<h3>BackEnd Development</h3>
    <div className="experience__content">
      <article className="experience__details">
      <BsPatchCheckFill className="experience__details-icon"/>
      <div><h4>NodeJS</h4>
      </div>      </article>
      <article className="experience__details">
      <BsPatchCheckFill className="experience__details-icon"/>
      <div><h4>MongoDB</h4>
      </div>      </article>
      <article className="experience__details">
      <BsPatchCheckFill className="experience__details-icon"/>
      <div><h4>PHP</h4>
      </div>      </article>
      <article className="experience__details">
      <BsPatchCheckFill className="experience__details-icon"/>
      <div><h4>ExpressJS</h4>
      </div>      </article>
    </div>
    </div>
    
    </div>
    
    <div className="container experience__containers">
    <div className="other_development">
<h3>Other Languages</h3>
    <div className="experience__contentss">
      <article className="experience__details">
      <BsPatchCheckFill className="experience__details-icon"/>
      <div><h4>Java</h4>
      </div>      </article>
      <article className="experience__details">
      <BsPatchCheckFill className="experience__details-icon"/>
      <div><h4>Python</h4>
      </div>      </article>
      <article className="experience__details">
      <BsPatchCheckFill className="experience__details-icon"/>
      <div><h4>VSCode</h4>
      </div>      </article>
      <article className="experience__details">
      <BsPatchCheckFill className="experience__details-icon"/>
      <div><h4>Github</h4>
      </div>      </article>
      </div>
    </div>

    </div>


    
    </section>
  )
}

export default Experience
import React from 'react'
import './portfolio.css'
import IMG1 from '../../assests/portfolio1.jpg'
import IMG2 from '../../assests/portfolio2.jpg'
import IMG3 from '../../assests/portfolio3.jpg'
import IMG4 from '../../assests/portfolio4.jpg'
import IMG5 from '../../assests/portfolio5.png'
import IMG6 from '../../assests/portfolio6.jpg'

const data = [
  {
  id: 1,
  image: IMG1,
  title: "XYZ University -School Website",
  github: 'https://github.com',
  demo: 'http://xyzuniversity.net/'
  },
  {
    id: 2,
    image: IMG2,
    title: "Project",
    github: 'https://github.com',
    demo: 'https://github.com'
    },
    {
      id: 3,
      image: IMG2,
      title: "Project",
      github: 'https://github.com',
      demo: 'https://github.com'
      },
      {
        id: 4,
        image: IMG2,
        title: "Project",
        github: 'https://github.com',
        demo: 'https://github.com'
        },
        {
          id: 5,
          image: IMG2,
          title: "Project",
          github: 'https://github.com',
          demo: 'https://github.com'
          },
          {
            id: 6,
            image: IMG2,
            title: "Project",
            github: 'https://github.com',
            demo: 'https://github.com'
            },
]

const Portfolio = () => {
  return (
    <section  id = 'portfolio'>
    
    <h2 className='aboutMe'>Portfolio</h2>

  <div className="container portfolio__container">
    {
      data.map(({id, image, title, github, demo}) => {
        return (
          <article key={id} className='portfolio__item'>
    <div className="portfolio__item-image">
      <img src={image} alt={title} />
    </div>
    <h3>{title}</h3>
    <div className="portfolio__item-cta">
    <a href={github} className='btn' target='blank'>Github</a>
    <a href={demo} className='btn btn-primary' target='blank'>Live Demo</a>
    
    </div>
    </article>
        )
        
      })
    }
  </div>
</section>
  )
}

export default Portfolio
import React from 'react';

const CTA = () => {
  return (
    <div className="cta">
      {/* /*<a href="https://github.com/Jibin-Biju" className="btn">
        Github
  </a>*/ }
      <a href="#portfolio" className="btn">
      Portfolio
      </a>
      <a href="#contact" className="btn">
        Contact
      </a>
    </div>
  );
};

export default CTA;

import { useCallback } from 'react';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import bgImg from '../assests/bg-texture.png';

const Design = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);
  return (
    <div className="w-full h-full">
      <Particles
    className="w-full h-screen fixed"
    id="tsparticles"
    init={particlesInit}
    loaded={particlesLoaded}
    options={{
        
        "particles": {
            "number": {
                "value": 8,
                "density": {
                    "enable": false,
                    "value_area": 1200
                }
            },
            
            "shape": {
                "type": "",
                "options": {
                    "sides": 0.1
                }
            },
          
            "rotate": {
                "value": 0.1,
                "random": true,
                "direction": "clockwise",
                "animation": {
                    "enable": true,
                    "speed": 1,
                    "sync": false
                }
            },
            "line_linked": {
                "enable": true,
                "distance": 1000,
                "color": "#ffffff",
                "opacity": 0.04,
                "width": 2
            },
            "move": {
                "enable": true,
                "speed": 0.7,
                "direction": "none",
                "random": false,
                "straight": false,
                "out_mode": "out",
                "attract": {
                    "enable": false,
                    "rotateX": 200,
                    "rotateY": 200
                }
            }
        },
        
        
       
       
    }}
      />
    </div>
  );
};

export default Design;

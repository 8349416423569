import React from 'react'
import'./header.css'
import CTA from './CTA'
import ME from '../../assests/me.png'
import HeaderSocial from './HeaderSocials'

const Header = () => {
  return (
    <header><div className="container header__container">
        <h5>Hello I'm</h5>
        <h1 >JIBIN BIJU</h1>
        <div class="wrapper">
        
        <div class="words"> 
        <span>Fullstack Developer</span>
        <span>Frontend Developer</span>
        <span>Backend Developer</span>
        </div>
        </div>
        <HeaderSocial />
        <CTA />
        
        <div className= "me">
            <img src={ME} alt="me" />

        </div>

        </div></header>
  )
}

export default Header
import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/jibin-biju-19b037228/" target="blank"><BsLinkedin/></a>
        <a href="https://github.com/Jibin-Biju" target="blank"><FaGithub/></a>
      


    </div>
  )
}

export default HeaderSocials
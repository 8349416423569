import React from 'react';
import './contact.css';
import { MdOutlineEmail } from 'react-icons/md';
import { BsWhatsapp } from 'react-icons/bs';
const Contact = () => {
  return (
    <section id="contact">
      
      <h2 className='aboutMe'>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
        <article className="contact__option">
          <MdOutlineEmail className='contact__option-icon'/>
          <h4>Email</h4>
          <h5>jibinvbiju@gmail.com</h5>
          <a href="mailto:jibinvbiju@gmail.com" target="blank">
            Sent a message
          </a>
        </article>
        <article className="contact__option">
          <BsWhatsapp className='contact__option-icon'/>
          <h4>WhatsApp</h4>
          <h5>347-593-2883</h5>
          <a href="https://api.whatsapp.com/send?phone=3475932883"
            target="blank"
          >
            Sent a message
          </a>
        </article>
      </div>
      <form action="">
        <input type="text" name='name' placeholder='Your Full Name' required />
        <input type="email" name='email' placeholder='Your Email' required />
        <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
        <button type='submit' className='btn btn-primary'>Send Message</button>
      </form>
      </div>
    </section>
  )
}

export default Contact
